import { MutableRefObject } from 'react';
import { FULL_SIZE, HALF_SIZE } from 'src/constants';

import { TBucketItemNo, TItemTypes } from './types/items';
import { IItemAsModifiers } from './order';

export interface Item {
  id: number;
  name: string;
  description: string;
  calories: number;
  price: number;
  category: string;
  image: string;
  is_try_2_combo?: boolean;
  is_kids_combo?: boolean;
}
export interface IItemsRequiredModifierGroups {
  id: number;
  name: string;
  brink_modifier_group_id: number;
  is_customizable_menu: number;
  label?: string;
  max?: number;
  min?: number;
  base: number;
  extendable_limit?: number;
  required_modifiers_groups_modifiers: IRequiredModifiersGroupsModifiers[];
  trayId?: number;
}
export interface IModifiersAgainstAnItem {
  itemId: TBucketItemNo<TItemTypes>;
}

export interface Items_Modifier_Groups {
  id: number;
  name: string;
  brink_modifier_group_id: number;
  is_customizable_menu: number;
  label?: string;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface ComplementaryItems {
  id: number;
  name: string;
  description: string;
  label?: string;
  min: number;
  max: number;
  is_customizable_menu: number;
  complimentary_modifier_group: number;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number | null;
    brink_modifier_group_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    is_selected: number;
    allergen_description: string | null;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface IRequiredModifiersGroupsModifiers {
  id: number;
  brink_modifier_id: number;
  name: string;
  price: number;
  image: string;
  calories: number;
  brink_modifier_group_id: number;
  max: number;
  in_item: boolean;
  is_selected?: number;
  required_modifier_location?: {
    status: string | null;
  }[];
  modifier_location: {
    status: string | null;
  }[];
  quantity?: number;
  modifier_id?: number;
}

export interface ICoreModifiers {
  id: number;
  brink_modifier_id: number;
  brink_modifier_group_id: number;
  quantity: string;
  name: string;
  price: number;
  image: string;
  calories: number;
  max: number;
  is_selected?: number;
  modifier_group_id?: number;
  modifier_group_min?: number;
  modifier_group_max?: number;
  modifier_group_extendable_limit?: number;
  modifier_location: {
    status: string | null;
  }[];
  core_ingredients_modifier_groups: {
    id: number;
    brink_modifier_group_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    min?: number;
    max?: number;
    modifiers_groups_modifiers: {
      id: number;
      brink_modifier_id: number;
      name: string;
      price: number;
      image: string;
      calories: number;
      max: number;
      modifier_location: {
        status: string | null;
      }[];
    }[];
  }[];
}

export interface IBucket {
  [key: string]: {
    quantity: number;
    modifiers: number[];
    price: number;
  };
}

export interface IModifierGroups {
  id: number;
  name: string;
  label: string;
  brink_modifier_group_id: number;
  min?: number;
  max?: number;
  base?: number;
  extendable_limit?: number;
  modifiers_groups_modifiers: {
    id: number;
    brink_modifier_id: number;
    name: string;
    price: number;
    image: string;
    calories: number;
    max: number;
    is_selected?: number;
    modifier_location: {
      status: string | null;
    }[];
  }[];
}

export interface ICategories {
  id: number;
  name: string;
  image: string;
  items: ItemDetails[];
  base?: number;
}

export interface ITry2Combo {
  name: string;
  description: string;
  items: ITry2ComboItem[];
}

export interface IKidsCombo {
  name: string;
  description: string;
  items: IKidsComboItem[];
}

export interface ITry2ComboItem {
  id: number;
  price?: number;
  name: string;
  items_modifier_groups: Items_Modifier_Groups[];
  sub_categories: ICategories[];
  complementary_items?: ComplementaryItems[];
  core_modifiers: ICoreModifiers[];
  items_required_modifier_groups: IItemsRequiredModifierGroups[];
}

export interface IKidsComboItem {
  id: number;
  price: number;
  name: string;
  description: string;
  image: string;
  items_modifier_groups?: Items_Modifier_Groups[];
  complementary_items?: ComplementaryItems[];
  core_modifiers?: ICoreModifiers[];
  items_required_modifier_groups?: IItemsRequiredModifierGroups[];
}

export enum Codes {
  ADD = 'ADD',
  SUB = 'ADD',
  NO = 'NO',
}
export interface IFavoriteItem {
  description: string;
  full_calories: number;
  full_calories_label: string;
  half_calories: number;
  half_calories_label: string;
  id: number;
  image: string;
  item_id: number;
  name: string;
  price: number;
  tags: any[];
  cart_directly: boolean;
  category_id: number;
  half_brink_item_id: number;
  calories: number;
  is_active: boolean;
  is_kids_combo?: boolean;
  is_try_2_combo?: boolean;
}

export interface IItemPathToRoute {
  pathname: string;
  search: string;
  state: { isPremiumPackage: boolean };
}

export interface IItemCustomization {
  item: any;
  showCustomizedOptions?: boolean;
  showCustomization?: boolean;

  handleIngredientSelection: (data: any, id?: number) => void;
  modalShow?: boolean;
  handleSyncIngredients?: (item: any) => void;
  autoScrollDivRef: MutableRefObject<HTMLDivElement>;
  firstTimeModal?: boolean;
  cardColSize?: any;
  boxLunchesModal?: string;
  manualSelectedItem?: any;
  hideModifierModal?: () => void;
  unrepeatedIngredients?: any[];
  getOrderButtonState?: (type: string) => boolean;
  itemAsModifierState?: {
    itemsAsModifier: IItemAsModifiers[];
    setItemsAsModifier: React.Dispatch<React.SetStateAction<any[]>>;
  };
  isCreateYourOwnItem?: boolean;
  CYOIScrollToMissingRequiredArea?: any;
  hideFirstTimeModal?: () => void;
  showFirstTimeModal?: () => void;
  modifiersRef?: any;
  dbId?: string;
  itemsAsModifiersToHandleScroll?: {
    refToScrollToMissingRequiredItemAsModifiers: any;
    isItemAsModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsItemAsModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
  manualSelectedItemId?: 2 | 3;
  openItemInfoModal?: (item: ItemDetails) => void;
}

export interface ItemDetails {
  full_brink_item_id: number;
  half_brink_item_id: number;
  full_item_label: string;
  half_item_label: string;
  price: number | any;
  description: string;
  half_brink_item_price: number;
  half_item_count: number;
  full_item_count: number;
  name: string;
  id: string | number;
  tags?: any;
  image: string;
  calories: number | any;
  is_package: boolean;
  is_premium?: boolean;
  is_customizable?: boolean;
  is_box_lunch: boolean;
  full_calories: number;
  is_active: number;
  items_modifier_groups: Items_Modifier_Groups[];
  core_modifiers: ICoreModifiers[];
  items_required_modifier_groups: IItemsRequiredModifierGroups[];
  item_location: {
    status: string | null;
  }[];
  half_loyalty_points?: number;
  full_loyalty_points?: number;
  category_name?: string;
  complementary_items?: ComplementaryItems[];
  bucket: IBucket[];
  full_brink_item_price: any;
  sub_categories: ISubCategories[];
  sub_items_required_modifier_groups?: subITemsAsMod[];
  default_manual?: ISubCategories[];
  default_selected?: ISubCategories[];
  category_id?: number;
  is_selected?: any;
  is_tray_item: boolean;
  cart_directly?: boolean;
  is_salad_tray: number;
  full_item_short_description?: string | null;
  half_item_short_description?: string | null;
  is_single_item?: boolean;
  package_categories?: ISubCategories[];
  is_create_your_own?: boolean;
  image_version?: string;
}

export interface subITemsAsMod {
  id: number;
  name: string;
  description: string | null;
  label: string;
  min: number;
  max: number;
  is_customizable_menu: number;
  is_required_item: number;
  complimentary_modifier_group: number;
  extendable_limit: number;
  no_modifier_label: string | null;
  base: number;
  min_mod_price: number;
  price_calc_type: string;
  required_modifiers_groups_modifiers: any[]; // Replace any with appropriate types if available
  items_as_modifier: ItemAsModifier[];
}
interface ItemAsModifier {
  id: number;
  name: string;
  sub_title: string | null;
  description: string;
  calories: number;
  calories_label: string;
  full_calories: number;
  full_calories_label: string;
  price: number;
  make_time_minutes: number;
  image: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  category_id: number;
  protein: string | null;
  is_active: number;
  is_86_able: number;
  reward_catalog: number;
  full_loyalty_points: number | null;
  half_loyalty_points: number | null;
  full_brink_item_id: number;
  half_brink_item_id: number | null;
  half_brink_item_price: number;
  half_brink_item_group_id: number | null;
  full_brink_item_group_id: number;
  new_half_brink_item_id: string;
  new_full_brink_item_id: string;
  is_delivery: number;
  is_pickup: number;
  is_third_party: number;
  show_in_app: number;
  infused_drink: number;
  canned_drink: number;
  app_image: string | null;
  half_item_label: string | null;
  half_item_short_description: string | null;
  half_item_count: number | null;
  full_item_label: string | null;
  full_item_short_description: string | null;
  full_item_count: number | null;
  counter_increment: number | null;
  is_box_lunch: number;
  is_soup_tray: number;
  is_sandwich_tray: number;
  is_salad_tray: number;
  is_package: number;
  is_default_for_package: number;
}

export interface ISubCategories {
  category_id: any;
  base: number | any;
  calories: number | any;
  description: string;
  name: string;
  items: ItemDetails[];
  package_option_label: string;
  default_manual_label?: string;
  id: number;
}
export interface IModifiersObjects {
  brink_id: number;
  brink_modifier_group_id: number;
  display_price: number;
  in_item: boolean;
  in_slide: boolean;
  max: number;
  modifier_calories: string;
  modifier_group_id: number;
  modifier_group_max: number;
  modifier_id: number;
  modifier_name: string;
  quantity: number;
  type: string;
  oldQuantity?: number;
}
export interface IComboSection {
  id: string;
  textStart: string;
  title: string;
  image?: string;
  onChange: (id: string, scroll?: boolean) => void;
  itemName?: string;
  changeItemSize: string;
  selectedItem?: string;
  sectionSelected?: string;
  onRemoveClickHandler?: (id: string) => void;
  onEditClickHandler?: (name: string, id: string) => void;
  checked?: boolean;
  handler?: string;
  onClick?: (boolean) => void;
  addedItem?: any;
  selectedtitle?: string;
  weight?: string;
  comingFromItem: string;
  kidComboItemDefaultName?: string;
  isItemSelected: boolean;
  isCustomizeable?: boolean;
  handleChangeTab?: (e) => void;
  isSingleItem?: boolean;
}
export interface INoDressingModifier {
  modifier_id: number;
  modifier_name: string;
  display_price: number;
  modifier_calories: string;
  type: string;
  modifier_group_id: number;
  modifier_group_max: number;
  modifier_type: string;
  in_slide: boolean;
  code: string;
  quantity: number;
  additionalPrice: number;
  groupId: number;
}
export interface IBucketSelectedIngredients {
  modifier_id: number;
  quantity: number;
  sub_with_id?: number;
  core: boolean;
  in_slide: boolean;
  modifier_group_id: number;
  additionalPrice?: number;
  display_price?: number;
}
export interface IBucketSelectedIngredients {
  modifier_id: number;
  quantity: number;
  sub_with_id?: number;
  core: boolean;
  in_slide: boolean;
  modifier_group_id: number;
  additionalPrice?: number;
  display_price?: number;
}
export interface INoDressing {
  noDressingHandler: (modifier: INoDressingModifier, itemNo: string) => void;
  isNoDressing?: boolean;
  itemNo: string;
  label?: string;
  groupId?: number;
  noDressingCardBorderRadius?: string;
  showRedBorderAnimation?: boolean;
}
export interface INoDressingModifier {
  modifier_id: number;
  modifier_name: string;
  display_price: number;
  modifier_calories: string;
  type: string;
  modifier_group_id: number;
  modifier_group_max: number;
  modifier_type: string;
  in_slide: boolean;
  code: string;
  quantity: number;
  additionalPrice: number;
  groupId: number;
}
export interface IOtherDressing {
  otherDressingHandler: () => void;
  isNoDressing?: boolean;
  styleClass?: string;
  selectCard?: boolean;
  groupMin: number;
  label: string;
  showRedBorderAnimation?: boolean;
}
export interface IOtherDressing {
  otherDressingHandler: () => void;
  isNoDressing?: boolean;
  styleClass?: string;
  selectCard?: boolean;
  groupMin: number;
  label: string;
}
export interface ISingleItemOtherDressing {
  modalShow: boolean;
  closeModal: () => void;
  otherDressingData: boolean;
  requiredModifierGroup: IItemsRequiredModifierGroups;
  noDressingHandler: (modifier: INoDressingModifier, itemNo) => void;
  removeSelectedIngredients: () => void;
  selectedIngredients: any;
  selectedAddedIngredients: IBucketSelectedIngredients[];
  updateSelectedModifiers: (modifier, id?: 1 | 2 | 3 | 4) => void;
  noDressing: boolean;
  selectedOtherDressingData: IModifiersObjects[];
  addSelectedIngredients: () => void;
  label: string;
  applyClickHandler: () => void;
  bucketkey?: any;
}
export interface ICurrentModifier {
  brink_id: number;
  brink_modifier_group_id: number;
  code: string;
  complimentary_modifier?: number;
  display_price: number;
  max: number;
  modifier_calories: string;
  modifier_group_id: number;
  modifier_group_max: number;
  modifier_group_base: number;
  modifier_id: number;
  modifier_name: string;
  in_item?: number;
  modifier_type: string;
  type: string;
  quantity: number;
  treat_as?: string;
  changes: {
    adjustedPrice: number;
    additionalPrice: number;
  };
  extendableLimitValue?: number;
  previousPrice?: number;
  modifier_group_min?: number;
  basePrice?: number;
  additionalPrice?: number;
  substitute_with_id?: null | number;
}
export interface IRequiredModifier {
  requiredModifierGroup: IItemsRequiredModifierGroups;
  setOtherDressingData: () => void;
  otherDressingHandler?: () => void;
  noDressingHandler?: (modifier: INoDressingModifier, itemNo: string) => void;
  selectedIngredients: IBucketSelectedIngredients[];
  selectedAddedIngredients: IBucketSelectedIngredients[];
  handleIngredientSelection: (data: any) => void;
  isInItemPresent?: boolean;
  setIsInItemPresent?: () => void;
  sectionSelected: string;
  isNoDressing?: boolean;
  noDressingModifiers?: ICurrentModifier[];
  showRecommendedButton?: boolean;
  addSelectedIngredients?: () => void;
  selectedOtherDressingData?: IModifiersObjects[];
  addItemtoCombo?: () => void;
  label: string;
  isRequiredModifierSelected?: boolean;
}
export interface ItemModal {
  id: string;
  title: string;
  sm_title: string;
}

export interface RecentOrderItem {
  brink_id: number;
  category: { id: number; name: string };
  category_id: number;
  category_name: string;
  combo_type: string;
  discount: number;
  display_price: number;
  free: boolean;
  gross_price: number;
  id: number;
  is_seasonal_item: null | boolean;
  item_id: number;
  modifiers: any[];
  name: string;
  order_id: number;
  price: number;
  quantity: number;
  recipient_name: null | string;
  set_id: null | boolean;
  single_size: number;
  size: string;
  total_net_price: number;
}
interface MakeAnItemTry2Combo {
  bucket: any; // You can replace 'any' with the appropriate type
  item: any; // You can replace 'any' with the appropriate type
  order: any; // You can replace 'any' with the appropriate type
}

export interface Order {
  size: null | string;
  modifiers: any[]; // You can replace 'any' with the appropriate type
  item_id: null | number;
  id: null | number;
  name: null | string;
  display_price: null | number;
  original_price: null | number;
  item_calories: null | number;
  reRender: boolean;
  makeAnItemTry2Combo: MakeAnItemTry2Combo;
  required_modifiers: any[]; // You can replace 'any' with the appropriate type
  other_modifiers: any[]; // You can replace 'any' with the appropriate type
  complimentary_modifier: any[]; // You can replace 'any' with the appropriate type
  core_modifiers: any[]; // You can replace 'any' with the appropriate type
}
export interface ItemDetailForm {
  quantity: number;
  name: string[];
  instructions: string;
  reset: boolean;
}

export interface IOptionalModifier {
  coreModifiers: ICoreModifiers[];
  modifierGroups: IModifierGroups[];
  requiredModifiers: IItemsRequiredModifierGroups[];
  handleIngredientSelection: (data: any) => void;
  selectedItem: string;
  sectionSelected: string;
  itemName: string;
  handleSyncIngredients: (item) => void;
  noDressingHandler?: (modifier: INoDressingModifier, itemNo: string) => void;
  isItEdit?: boolean;
  showCustomization?: boolean;
  order?: any;
  itemModifierItemModalWidth?: string;
  Item?: ItemDetails;
  isCYOIColSize: string;
  isCreateYourOwnItem: boolean;
}
export interface IParams {
  modifier_id: number;
  modifier_name: string;
  display_price: number;
  modifier_calories: number;
  brink_id: number;
  type: string;
  quantity?: number;
  max?: number;
  extendableLimitValue?: number;
  modifier_group_id: number;
}
export interface RouteState {
  size?: string;
  name?: string;
  free?: boolean;
  toClaimCategory?: boolean;
  reward_id?: number;
}

export interface IRefStatesForScrolling {
  refToScrollToMissingRequiredModifiers: any[];
  isRequiredModifierSelected: {
    status: boolean;
    highLightArea: number;
  };
  setIsRequiredModifierSelected: React.Dispatch<
    React.SetStateAction<{ status: boolean; highLightArea: any }>
  >;
}

export const mapSizesKey: any = {
  [HALF_SIZE.id]: 'size_one_count',
  [FULL_SIZE.id]: 'size_two_count',
  basePrice: {
    [HALF_SIZE.id]: 'size_one_base',
    [FULL_SIZE.id]: 'size_two_base',
  },
};
