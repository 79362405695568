
import { cartItem } from "../../models/cart.model";
import ReactGA from "react-ga4";
import { getUser } from "../helperMethods";
import { toFixedNumber } from "src/priceCalculation/helper";
import { cartSubTotal } from "../cartHelperMethods";
import TagManager from "react-gtm-module";

const userId = getUser()?.id || null;

export const getStructuredItem = (item: cartItem) => {
    return {
        'item_id': item?.id,
        'item_name': item?.name,
        'discount': item?.discount_amount || item?.discount || 0,
        'index': item?.index || 0,
        'item_brand': "Cafe Zupas",
        'item_category': item?.category_name?.toString() || item?.category_id?.toString(),
        'item_list_id': item?.category_id,
        'item_list_name': item?.category_name || item?.name?.toString(),
        'location_id': item?.locationId || 15,
        'price': item?.display_price || item?.base_price || item?.price,
        'quantity': item?.quantity || 1
    }
}

export const getStructuredItemList = (cartItems: cartItem[], locationId) => {
    let items =
        cartItems?.map((item: cartItem) => {
            return getStructuredItem({ ...item, locationId })
        })
    return items
}

export const pushGAViewItemListEvent = (menu, locationId) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'view_item_list',
            'user_id': userId || null,
            'item_list_id': menu?.id,
            'item_list_name': menu?.name,
            'items': getStructuredItemList(
                menu?.items,
                locationId
            )
        }
    })
}

export const pushGASelectItemEvent = (item) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'select_item',
            'item_list_id': item?.category_id,
            'item_list_name': item?.category_name,
            'items': getStructuredItem(
                item
            ),
        }
    })
}

export const pushGAAddToCartEvent = (item) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'add_to_cart',
            'currency': "USD",
            'value': item?.base_price,
            'items': getStructuredItem(
                item
            )
        }
    })
}

export const pushGAViewItemEvent = (item) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'view_item',
            'user_id': userId || null,
            'item_list_id': item?.category_id,
            'item_list_name': item?.category_name,
            'items': getStructuredItem(
                item
            )
        }
    })
}

export const pushGALoginEvent = (user) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'login',
            'login_method': "email",
            'new_user_id': user.id
        }
    })
}

export const pushGASignupEvent = (user) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'signup',
            'signup_method': 'email',
            'new_user_id': user?.id
        }
    })
}

export const pushGAAddToWishlistEvent = (item) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'add_to_wishlist',
            'currency': "USD",
            'value': item?.price,
            'items': [
                getStructuredItem(item as any)
            ]
        }
    })
}

export const pushGARemoveFromCartEvent = (item) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'remove_from_cart',
            'user_id': userId,
            'currency': "USD",
            'value': item?.display_price,
            'items': [
                getStructuredItem(item as any)
            ]
        }
    })
}

export const pushGASelectPromotionEvent = (items, offer, locationId, offerDisplay) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'select_promotion',
            'user_id': userId,
            'currency': "USD",
            "creative_name": offer?.name,
            "creative_slot": offerDisplay,
            "promotion_id": offer?.loyalty_offer_id,
            "promotion_name": offer?.name,
            'items': [
                getStructuredItemList(
                    items,
                    locationId
                )
            ],
        }
    })
}

export const pushGAViewPromotionEvent = (offers, locationId, offerDisplay) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'view_promotion',
            'user_id': userId,
            'currency': "USD",
            'items': offers?.map((offer) => {
                return {
                    "creative_name": offer?.name,
                    "creative_slot": offerDisplay,
                    "promotion_id": offer?.loyalty_offer_id,
                    "promotion_name": offer?.name,
                }
            })
        }
    })
}

export const pushGASelectLocationEvent = (location) => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'select_location',
            'location_name': location?.name,
            'location_id': location?.id
        }
    })
}

export const pushGAPurchaseEvent = (order: any) => {
    TagManager.dataLayer({
        dataLayer: {
            event: "purchase",
            user_id: userId,
            ecommerce: {
                transaction_id: order?.id,
                value: toFixedNumber(Number(order?.total_price) + Number(order?.tip)),
                tax: order?.tax,
                shipping: order?.delivery_fee,
                currency: "USD",
                coupon: order?.discount,
                items:
                    getStructuredItemList(
                        order?.items,
                        order?.location_id
                    )
            },
        }
    })
}

export const pushGARefundEvent = (order: any) => {
    TagManager.dataLayer({
        dataLayer: {
            event: "refund",
            user_id: userId,
            ecommerce: {
                transaction_id: order?.id,
                value: toFixedNumber(Number(order?.total_price) + Number(order?.tip)),
                tax: order?.tax,
                shipping: order?.delivery_fee,
                currency: "USD",
                coupon: order?.discount,
                items:
                    getStructuredItemList(
                        order?.items,
                        order?.location_id
                    )
            }
        }
    })
}

export const pushGAViewCartEvent = (items: any, locationId) => {
    TagManager.dataLayer({
        dataLayer: {
            event: "view_cart",
            currency: "USD",
            value: cartSubTotal(items),
            items:
                getStructuredItemList(
                    items,
                    locationId
                )
        }
    })

}

export const pushGABeginCheckoutEvent = (items: any, locationId) => {
    TagManager.dataLayer({
        dataLayer: {
            event: "begin_checkout",
            user_id: userId,
            currency: "USD",
            value: cartSubTotal(items),
            items:
                getStructuredItemList(
                    items,
                    locationId
                )
        }
    })
}