import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { flushSync } from 'react-dom';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as CONSTANT from 'src/constants';
import { ROUTE_LOCATIONS } from 'src/constants';
import { setLocation } from 'src/Features/Location/redux/slice';
import { handleIsLiveLocationRedirection } from 'src/helper/checkout/LocationHelper';
import { getVisitorId } from 'src/helper/helperMethods';
import { ISelectedLocation } from 'src/models/forms.model';
import { IItemPathToRoute } from 'src/models/item.model';
import { IGetCartPayload } from 'src/models/order.model';
import {
  setShowCart,
  updateCartRedeemedOfferItem,
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { locationMenuService } from 'src/services';
import { selectLocationSchema } from 'src/validationSchemas/selectLocationSchema';

import Button from '../Button/Button';
import FormField from '../FormFields/FormField';
import useSyncCart from 'src/hooks/useSyncCart';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';
import ConfirmLocationChangeModal from './ItemPickup/ConfirmLocationChangeModal';
import { pushGASelectLocationEvent } from 'src/helper/scripts/seoScript';

interface IItemPickup {
  itemPath: IItemPathToRoute;
  closeOpenOrderModal: () => void;
  showItemPickupDateTime?: boolean;
  handleShowPickupMethodModal?: (boolean) => void;
  saveSelectedOrderType?: string;
  handleSavedOrderType?: () => void;
  isItMobile: boolean;
  isCheckout: boolean;
  closeModal?: boolean;
  routeTo?: string;
  initiator?: string;
  goBack?: boolean;
  showModal?: boolean;
}

function ItemPickup(props: IItemPickup) {
  const {
    itemPath,
    closeOpenOrderModal,
    showItemPickupDateTime,
    handleShowPickupMethodModal,
    saveSelectedOrderType,
    isItMobile,
    isCheckout,
    goBack,
    showModal,
    routeTo,
    initiator,
  } = props;
  const history = useHistory();
  const orderTypeDispatch = useAppDispatch();
  const locationDispatch = useAppDispatch();
  const visitor_id = getVisitorId();
  const queryClient = useQueryClient();
  const location = useLocation();
  const locationInfo = useAppSelector((state) => state.location);
  const { user } = useAppSelector((state) => state.user);
  const cart = useAppSelector((state) => state.cart);
  const { syncCart, loading: syncingCart } = useSyncCart();
  const [isFetchingLocations, setIsFetchingLocations] = useState<boolean>(
    user.type !== CONSTANT.GUEST_USER,
  );
  const isPrimaryCapacityFormFilled = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity.formFilled,
  );
  const location_id = locationInfo.selectedStore?.id;
  const previousOrderType = cart?.orderType;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [markets, setMarkets] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    locationInfo.selectedStore,
  );

  const selectedLocationOption = {
    value: locationInfo.selectedStore?.name,
    label: selectedLocation.name,
    id: selectedLocation.id,
  };
  const initialFormState: ISelectedLocation = {
    selected_location: selectedLocation.id ? selectedLocationOption : null,
  };
  const getLocations = async (payload) => {
    try {
      const response = await locationMenuService.getNearbyLocations(payload);
      if (response) setIsFetchingLocations(false);
      const responseLocations = response?.data?.data;
      setMarkets(responseLocations);
      setAllLocations(
        responseLocations?.map((market) => ({
          value: market.name,
          label: market.name,
          id: market.id,
          ...market,
        })),
      );
    } catch (error) {
      const errorMessage =
        error?.response?.data?.errors?.[0] || error.message || 'Unknown error';
      bugSnagNotifyAPIError('Nearby locations', payload, errorMessage);
    }
  };
  const handleLocationSearch = () => {
    closeOpenOrderModal();
    orderTypeDispatch(setShowCart(false));
    history.push({
      pathname: ROUTE_LOCATIONS,
      state: {
        itemPath: itemPath,
        goBack: goBack,
        routeTo: routeTo,
        initiator: initiator,
      },
    });
  };

  const handleContinueButton = () => {
    if (!selectedLocation) {
      history.push({
        pathname: ROUTE_LOCATIONS,
        state: {
          itemPath: itemPath,
          goBack: goBack,
          routeTo: routeTo,
          initiator: initiator,
        },
      });
      orderTypeDispatch(setShowCart(false));
    } else {
      const shouldShowConfirmation = handleConfirmationModal();
      if (shouldShowConfirmation) {
        setShowConfirmationModal(true);
        return;
      }
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      orderTypeDispatch(updateOrderType(CONSTANT.PICK_UP_ORDER_TYPE));
      orderTypeDispatch(updateOrderDetails(null));
    }
    closeOpenOrderModal();
  };
  const getSelectedLocation = (location) => {
    return allLocations.find((loc) => loc.id === location?.id)
      ? location
      : null;
  };
  const getLocationDropdown = () => {
    const payload: any = { user_id: user.id, favorites_only: true };
    getLocations(payload);
  };
  const confirmLocationChange = () => {
    if (!selectedLocation.is_live) {
      handleIsLiveLocationRedirection(
        saveSelectedOrderType,
        selectedLocation,
        isItMobile,
      );
    } else {
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      // setLocation(locationDispatch, selectedLocation);
      locationDispatch(setLocation(selectedLocation));
      pushGASelectLocationEvent(selectedLocation);
      const payload: IGetCartPayload = {
        location_id: selectedLocation.id.toString(),
      };
      if (user.id) payload.customer_id = user.id;
      else payload.visitor_id = visitor_id;
      queryClient.refetchQueries(['get-cart', selectedLocation.id]);
      orderTypeDispatch(updateOrderDetails(null));
      closeOpenOrderModal();
      if (saveSelectedOrderType) {
        handleSaveOrderType(saveSelectedOrderType);
      }
      if (showItemPickupDateTime) {
        handleShowPickupMethodModal(true);
      }
      if (showConfirmationModal) {
        setShowConfirmationModal(false);
        history.push(CONSTANT.ROUTE_MENU);
      } else if (itemPath) {
        const goto =
          isPrimaryCapacityFormFilled || cart.is_edit_order
            ? itemPath
            : {
              pathname: CONSTANT.ROUTE_START_CATERING_ORDER,
              state: { initiator: initiator, itemPath: itemPath },
            };
        history.push(goto);
      }
      // if (cart.free_delivery) {
      //   orderTypeDispatch(
      //     updateCartRedeemedOfferItem({
      //       is_redeemed_by_offer: false,
      //     }),
      //   );
      //   syncCart(selectedLocation.id);
      // }
    }
  };
  const onCloseConfirmationMoal = () => {
    setShowConfirmationModal(false);
    setIsSubmitting(false);
  };
  const handleConfirmationModal = () => {
    return (selectedLocation.id !== location_id || previousOrderType !== saveSelectedOrderType)
      && location.pathname.includes(CONSTANT.ROUTE_OTHER_ITEM)
  }
  const handleSaveOrderType = (orderType: string) => {
    orderTypeDispatch(updateOrderType(orderType));
  };
  const routeToItem = () => {
    setIsSubmitting(true);
    const shouldShowConfirmation = handleConfirmationModal();
    if (
      shouldShowConfirmation
    ) {
      setShowConfirmationModal(true);
    } else {
      confirmLocationChange();
    }
  };
  useEffect(() => {
    // getLocationsWithoutPermission();
    if (user.type !== CONSTANT.GUEST_USER) getLocationDropdown();
  }, []);

  // useEffect(() => {
  //   if (!showModal) {
  //     history.push({
  //       pathname: ROUTE_LOCATIONS,
  //       state: { itemPath: itemPath, goBack: goBack },
  //     });
  //   }
  // }, [showModal]);

  return isFetchingLocations ? (
    <div className="mt-4">
      <Spinner />
    </div>
  ) : (
    <>
      <div className="mt-5">
        {allLocations?.length ? (
          <>
            <Formik
              initialValues={initialFormState}
              onSubmit={routeToItem}
              validationSchema={selectLocationSchema}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <form
                  className="full_width_mob new_form_design my-0 d-flex flex-column"
                  onSubmit={handleSubmit}
                >
                  <FormField
                    isSearchable={true}
                    options={allLocations?.filter(
                      (location) => location.is_live,
                    )}
                    labelText={'Favorite Locations'}
                    name="selected_location"
                    type={'select'}
                    placeholder={'Select Location'}
                    errors={errors}
                    touched={touched}
                    value={getSelectedLocation(values.selected_location)}
                    onChange={(location) => {
                      setFieldValue('selected_location', location);
                      setSelectedLocation(location);
                    }}
                    inputFieldClass={
                      'custom_select custom_multi_select mb-0 mt-2 mb-4 pb-md-0 fav_location_select'
                    }
                  />
                  {selectedLocation && (
                    <div className="text-start mb-4">
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.name}
                      </span>
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.address}
                      </span>
                      <span className="d-block f-s14 font-Visby-cf f-w5">
                        {selectedLocation.city}, {selectedLocation.state}{' '}
                        {selectedLocation.zip}
                      </span>
                    </div>
                  )}
                  <Button
                    className="w-100"
                    // disabled={isSubmitting || !markets}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Continue
                  </Button>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="text-start">
              {selectedLocation && (
                <div className="my-4">
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.name}
                  </span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.address}
                  </span>
                  <span className="d-block f-s14 font-Visby-cf f-w5">
                    {selectedLocation.city}, {selectedLocation.state}{' '}
                    {selectedLocation.zip}
                  </span>
                </div>
              )}
              <Button className="w-100 mt-4" onClick={handleContinueButton}>
                Continue
              </Button>
            </div>
          </>
        )}
        <Button className="btn-text mt-4" onClick={handleLocationSearch}>
          {selectedLocation ? 'Change Location' : 'Search Locations'}
        </Button>
        <ConfirmLocationChangeModal
          showModal={showConfirmationModal}
          handleClose={() => onCloseConfirmationMoal()}
          confirmChange={confirmLocationChange}
          loading={false}
        />
      </div>
    </>
  );
}

export default ItemPickup;
